import * as React from "react"
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>IRONMAN Chattanooga Race Report</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="IRONMAN Chattanooga Race Report" />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/ironman-race-report/meta.jpg"
        />

        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
          integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
          crossorigin="anonymous"
        />

        <link href="/race-report.css" rel="stylesheet" />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.min.js"
          integrity="sha384-skAcpIdS7UcVUC05LJ9Dxay8AXcDYfBJqt1CJ85S/CFujBsIzCIv+l9liuYLaMQ/"
          crossorigin="anonymous"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>

      <div className="container-fluid" style={{overflow: "hidden"}}>
        <div className="row" style={{ margin: "auto -30px" }}>
          <div className="col-12">
            <img
              className="img-fluid"
              src="/images/ironman-race-report/header.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="px-4 py-4 text-center border-bottom">
          <h1 className="display-4 fw-bold">IRONMAN Chattanooga Race Report</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-0">September 26, 2021</p>
          </div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <p>So we’ll start this race report with a strong statement:</p>

          <p>
            Behind my wedding day, the second most impactful day of my life?
            Ironman Chattanooga.
          </p>

          <p>
            This journey started way back at the end of September 2019. That’s
            when I signed up for the race. A full Ironman triathlon is a
            2.4-mile swim, a 112-mile bike ride, and a 26.2-mile marathon run.
            For extra fun, Ironman Chattanooga is slightly longer. The bike ride
            is 116 miles putting the total distance at 144.6 miles.
          </p>

          <p>
            I’d planned to race on September 27, 2020. Those first few months of
            training were pretty uneventful. But then 2020 arrived.
          </p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>A Tornado, a Pandemic, and a Bombing</h1>
          <div className="float-right">
            <figure class="figure">
              <img src="/images/ironman-race-report/lockscreen.jpg" alt="" />
              <figcaption class="figure-caption">My lock screen background is Chattanooga, and a single push notification to sum up a shitty year.</figcaption>
            </figure>
          </div>
          <p>On March 3, an EF3 tornado moved through Nashville killing 24 people
          and causing millions of dollars in damage. My pool near work closed
          because it became a shelter for tornado victims. The COVID lockdowns
          arrived a week later. On Christmas Day, a lunatic set off a bomb
          outside of AT&amp;T in downtown. We had no 911, land, cell, and internet
          service for three days. I called my Mom to tell her we were okay and to wish her a Merry Christmas from a gas station. It was a terrible year for the world, but it
          was an especially tough year in Music City.</p>
          <p>The footnote of all footnotes: My entire 2020 race calendar ended up canceled including Ironman Chattanooga.</p>
          <p>It wasn’t lost on me that this pandemic wrecked many people’s mental health. But I felt like I’d been mostly unscathed because I had triathlon.</p>
          <p>It’s hard to keep up the motivation for daily training in a pandemic. I didn’t even have a pool for the first two months during the “Shelter in Place” days of spring. I had no idea when or where I’d race again. But I found that I enjoyed the sport even when there were no events to train for.</p>
          <p>I had a daily dose of sweat, endorphins, and a reminder of what I’d get to do when the pandemic ended. Sticking to a training schedule also gave each day a unique structure. Without it, each day of quarantine would have felt exactly the same.</p>
          <p>My goal of becoming an Ironman had just been delayed, not lost. But more importantly, I stayed healthy. And triathlon kept me happy and upbeat during a truly dark time.</p>
          <div class="clearfix"></div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>A Summer of PRs</h1>
          <div className="float-right">
            <img src="/images/ironman-race-report/podium.jpg" alt="" />
          </div>
          <p>As the world shifted into its new normal, all of my postponed 2020 races returned for 2021. Most of these events I had done before. So when it was time to race them again in 2021, Susie had high expectations: smashing my PR in each discipline for each event. I was skeptical.</p>

          <p>Amazingly, I did just that. April, May, and June were awesome.</p>

          <p>I ended up on the podium in Knoxville. I had my first sub-3 hour bike ride in a 70.3 in Chattanooga. Just two short weeks later, on tired legs, I raced the inaugural Blue Ridge 70.3, which included 4000 feet (!!!) of climbing during the bike ride. I had my fastest ever Olympic distance triathlon in Chattanooga. I ran my fastest ever 10k in Atlanta.</p>

          <p>This all exceeded my wildest expectations. I didn’t come out from the pandemic rusty. I came out on fire. With each PR, my confidence continued to grow for the full Ironman at the end of September.</p>
          <div class="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>The Dog Days of Summer</h1>
          <p>This journey wasn’t without woes. July was *hard*, y’all. Hard enough that I actually started wondering if it was a good idea to keep at this. Physically I felt great, but mentally I was completely overwhelmed.</p>

          <p>The last week of July, I had a huge code deployment at work, the Music City Triathlon, and a conference speaking gig up in Wisconsin. It stretched me thin, and I admittedly was not as attentive of a husband as I should have been. Nothing was fun anymore, and that’s before you factor in hurting your wife. I felt like shit.</p>

          <div className="float-right">
            <figure class="figure">
              <img src="/images/ironman-race-report/conference.jpg" alt="" />
              <figcaption class="figure-caption">The dress code for software engineering conferences is quite formal.</figcaption>
            </figure>
          </div>

          <p>A respectable set of goals for an *entire year* would have been to successfully deploy our new site for work, speak at a conference, and complete an Olympic distance triathlon. Those all ended up happening across the span of just *four days*.</p>

          <p>It wasn’t a fun experience, but what I tried to learn from this is that I’m capable of keeping going even when I feel completely overwhelmed. It would have been awful to drop out of the race or bail on the conference last minute because I was stressed. But I didn’t give up. We got the site deployed, I finished Music City, spoke at the conference, and apologized to Katy.</p>

          <p>I had little control over how these things lined up the way they did on my calendar. But I can control how I respond to them. It also reminded me that when times get hectic, I need to keep check of my priorities. I put things ahead of family, which is never a good decision.</p>
          <div class="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>The Delta Variant</h1>

          <p>The final 60 days of Ironman training were upon me. In terms of COVID-19, things weren’t good in Tennessee. Our vaccination rates were low, and cases were high. While I pushed through my final two training blocks with #TeamModerna’s antibodies, I couldn’t help but nervously look at those surging case numbers. Surely they won’t cancel this race a second year in a row? Right? RIGHT???</p>

          <p>But while I worried about the case count, I got back to crushing my training. A 10-mile run before work wasn’t that big of a deal anymore. I day-tripped to Chattanooga and rode the course with no issues. Susie was throwing long workouts at me day after day, and things were going great. I didn’t have any lingering late-season injuries. But even better, mentally, I felt strong.</p>

          <p>When race week finally arrived, I was as amped as I’ve ever been for an event. There wasn’t a doubt in my mind this would go well. I told Susie the confidence was almost unnerving, but it also seemed to feel justified. I got goosebumps about a dozen times walking around registration. I finally had made it to a full Ironman event!</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/im-village.jpg" alt="" />
              <figcaption class="figure-caption">Love the Little Debbie branding.</figcaption>
            </figure>
          </div>

          <div class="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>The Swim</h1>
          <p>I woke up at 3:45 am before my alarm. I felt well-rested and excited AF. As I rode the bus to the swim start, I realized that whatever happened today, I’d remember it for the rest of my life. That’s a scary realization but also a very cool moment to be in the midst of.</p>

          <p>I’d been waiting two years for this. Since I signed up for the race, I had swum, biked, or ran a collective 7,096 miles. It took me 35 days, 13 hours, 6 minutes, and 52 seconds. I’d put so much work into this one particular day. With that kind of time invested in this, it shocked me how calm I felt waiting in a sea of humanity to hop in the river.</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/swim-start.jpg" alt="" />
              <figcaption class="figure-caption">If you want to play Where's Colin, I'm standing in the bottom right corner of the image, in front of the pile of morning clothes bags.</figcaption>
            </figure>
          </div>

          <p className="pb-4">I loved jumping off the dock into the river. I could finally stop thinking about an Ironman because now I was doing one!</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/dock-aerial.jpg" alt="" />
              <figcaption class="figure-caption">Drones are so freaking awesome.</figcaption>
            </figure>
          </div>

          <p>About 500 yards into the swim, I felt great. But I briefly stopped swimming, took a moment to tread water, look all around, and take in the moment. I was in the Tennessee River with 2000 athletes from across the world. I was healthy both physically and mentally. The weather was terrific. This day was perfect.</p>

          <p>There’s a mental picture in my mind from this moment that I’ll never forget.</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/river-aerial.jpg" alt="" />
              <figcaption class="figure-caption">Another awesome drone view of the swim with fog in the disance in front of Lookout Mountain.</figcaption>
            </figure>
          </div>

          <p>Four years ago, the only swimming I knew how to do was floating in the ocean, holding a beer. Now I’m in a freaking Ironman. And not only that, I was having the swim of my life. When I reached the halfway point at 1.2 miles, I realized I was on pace to finish the swim in under AN HOUR. (That’s quick, even for talented athletes!) As much as it pained me to throttle it back, I knew I had 143 miles ahead of me. This wasn’t the day to go crazy so early. (Maybe next time!)</p>

          <p>When you finish the swim, you run up a set of stairs onto a large sidewalk. If you’re smart, you give your legs some extra kicking over the final 50 yards or so to get the blood flowing again. I forgot to do that. So when I got out of the water and was upright for the first time in an hour, I fell right back over landing squarely on my right thumb. OUCH!</p>

          <p>I legit thought I broke it. It’s hard to describe, but it just felt like a different kind of pain. Something seemed really wrong. But my first thought? “Fuck this, nothing you can do now if it’s broken. Plus, you don’t need your thumb to bike or run. You still got this!” The slow build of confidence I’d accumulated over the last two years played a significant role throughout the day. I thought I’d broken my thumb, and it didn’t phase me!</p>

          <p>In my race photos, I’m actually somehow *smiling* moments after this happened. My right thumb looks...a little off in the second photo. Oof.</p>

          <div className="two-image">
            <img src="/images/ironman-race-report/swim-finish1.jpg" alt="" />
            <img src="/images/ironman-race-report/swim-finish2.jpg" alt="" />
          </div>

          <div class="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>The Bike</h1>
          <p>I zoomed out of Chattanooga a lot faster than I should have been. Throttling it back at the beginning of each discipline became a theme today. As much as I hated to dial things down, my plan for the bike ride was to do the course in 7 hours. That’s a good bit slower than I’m capable of, but there was a 26-mile marathon looming on the other side.</p>

          <p>The bike ride was generally uneventful. My time? 7:02. I paced myself just about perfectly.</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/bike-aerial.jpg" alt="" />
              <figcaption class="figure-caption">Seriously, I love all these drone pictures.</figcaption>
            </figure>
          </div>

          <p>Given that this took 7 hours, there’s plenty of stuff I could talk about here. But honestly, it was a generally uneventful ride. There were no mechanical issues with my bike, no nutrition issues, and I churned out the ride without any drama.</p>

          <p>It was a gorgeous ride on a perfect day in Tennessee and Georgia, my two favorite states. What could have been better?</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/bike-barn.jpg" alt="" />
            </figure>
          </div>
          <div class="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>The Run</h1>
          <p>As I came out of transition for the *first* 26-mile marathon of my life, my confidence had never been higher. I felt like if something were to go wrong, it would happen on the bike. There were no mechanical bike issues to worry about anymore. It’s just my two feet! Nothing is going to go wrong now! (Spoiler alert: that wasn’t the case.)</p>

          <p>My plan for the run was to pace around a 10-10:30 mile. Like with the bike, that felt annoyingly slow. But it seemed reasonable. The first ten miles went to plan. I was feeling great! I was going to be an Ironman! Only 16 miles to go! This is happening!</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/run-start.jpg" alt="" />
            </figure>
          </div>

          <p>Without much warning, I started feeling very dizzy. I slowed to a walk and tried to assess the situation. I’d never felt anything like this. While I felt great neck down, I was legit having trouble keeping my balance. It took me 6-7 miles of walking to realize this was a nutritional issue. My body wanted a lot more calories and even more salt.</p>

          <p>This was an unpleasant moment. It lasted more than an hour. I went from being upset I wasn’t sticking to my goal pace to wondering if I’d be able to finish. It was a lonely, sinking feeling. What if this got worse? Was I really going to come 130 miles only to literally tip over during the final 16? But once I started munching on salty food, I felt things turning around. In hindsight, chalk this up to nutrition inexperience at this kind of distance. I’ll get better next time!</p>

          <p>While I’ve never done a marathon before, I’ve often heard the final 6 miles are an exceptional challenge. As I approached mile 20 and began to pull out of my scary lightheadedness thanks to many handfuls of chips, I felt pretty great! Upside of having to walk for a bit meant I had some extra juice here at the end! But I decided to err on the conservative side and not run too hard. At this point, my time was irrelevant. Let’s just finish at a jogging pace.</p>

          <p>It was a gorgeous evening. The weather was perfect. Slowing to a jog actually ended up being a blessing. It enabled me to soak in the final miles. There were no doubts anymore. If I got dizzy again, I’d just crawl. (Seriously.) I had less than a mile to go as I crossed the Market Street Bridge. I could now see the lights of the finish line. Holy shit, I’m about to be an Ironman.</p>

          <p>To finally get to enter the finisher chute of a full Ironman race and hit that red carpet is an indescribable feeling. It’s something I’d thought about for over two years. Katy, my Mom, and Dave were off to the left. I gave them a wave and then looked right at the finish line.</p>

          <p>There are so many lights focused right on you that it blacks out everything else around you. I could hear cheering, I could hear music, but outside of those finish line lights, everything else around me appeared pitch dark by comparison. Everyone who finishes is literally running out of darkness into bright, warm lights.</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/finish-aerial.jpg" alt="" />
            </figure>
          </div>

          <p>I heard the race announcer say the magical words “Colin Lord, you are an Ironman!” Whenever I had thought about that moment, I wondered if I would cry, I wondered if I’d scream in happiness, I legit didn’t know. In actuality, I felt so many different emotions in the span of a few seconds. Relief. Pride. Regret over the run. Disbelief I’d actually made it through.</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/finish1.jpg" alt="" />
            </figure>
          </div>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/finish2.jpg" alt="" />
            </figure>
          </div>

          <p>I just became an Ironman! I just finished the longest Ironman course in the world!</p>
          <div class="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>What's Your Why?</h1>
          <p>Susie put this note on my training schedule two weeks before race day. I’ve already alluded to the answer in this race report.</p>

          <div className="full-image">
            <figure class="figure">
              <img src="/images/ironman-race-report/whats-your-why.jpg" alt="" />
            </figure>
          </div>

          <p>One of the reasons I have embraced triathlon is it gives me tangible evidence I’m not the Colin of the 2000s. While admittedly dealt a shit hand back then, I didn’t respond well to the situation either. During that time, the smallest thing could ruin my whole week. Resilience was a skill I lacked. Back then, I was constantly sad, angry, and overwhelmed by the world. It’s a terrible place to be mentally.</p>

          <p>Since then, I’ve put a lot of work into becoming a different, happier, and better person. But one of my biggest fears is that somehow I’ll backslide. I’ll become *that* person again. Mental health can be a slippery slope. Once things get rolling in a bad direction, by the time you realize it, you’re often already in a terrible place.</p>

          <p>The younger version of Colin could have never done this. He’d have panicked before jumping in the river. Or almost certainly quit the race after busting his thumb. He definitely lacked the resilience to get through being lightheaded during the run. I’m not him anymore.</p>

          <p>Training for an Ironman has presented me with daunting challenges. It has taken me to some dark places of serious self-doubt, sometimes on the side of the road many miles from home. But it’s also taught me resilience. I’ve seen that resilience in many different parts of my life. This race has literally made me a better person. And that resilience makes it far less likely I’ll ever be *that* person again when things get tough. That’s my why.</p>

          <p>Outside of getting married to Katy, nothing has had a more positive impact on my life than this sport. I’m proud of the miles, but I’m just as proud of the happy, resilient person it’s helped me become. Rise up!</p>
          <div class="clearfix"></div>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
